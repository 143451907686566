@mixin fontBase() {
    font-family: source-sans-pro-regular;
    font-weight: 400;
}

@mixin fontBold() {
    font-family: source-sans-pro-bold;
    font-weight: 400;
}

.tps-heading-1 {
    @include fontBold();
    font-size: 48px;
    font-weight: 700;
    color: var(--dark-color);
}

.tps-heading-2 {
    @include fontBold();
    font-size: 32px;
    font-weight: 700;
    color: var(--dark-color);
}

.tps-heading-3 {
    @include fontBold();
    font-size: 24px;
    font-weight: 700;
    color: var(--dark-color);
}

.tps-heading-4 {
    @include fontBold();
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
}

.tps-heading-5 {
    @include fontBold();
    font-size: 18px;
    font-weight: 700;
    color: var(--dark-color);
}

.tps-sub-heading-1 {
    @include fontBase();
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: var(--primary-color);
}

.tps-sub-heading-2 {
    @include fontBase();
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--dark-color);
}

.tps-body-1 {
    @include fontBase();
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--dark-color);
}

.tps-body-2 {
    @include fontBase();
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    color: var(--dark-color);
}

.tps-text-small {
    @include fontBase();
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
}

.tps-muted {
    color: var(--light-grey-color);
}
