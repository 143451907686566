// Hint: This file is used for changing google components like autocomplete suggestion box

// Autocomplete suggestion box
// --------------------------------
.pac-container {
    background-color: #fff;
    position: absolute !important;
    z-index: var(--autocomplete-z);
    border-radius: var(--button-border-radius);
    border-top: none;
    font-family: 'source-sans-pro-regular';
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
}

.pac-item {
    cursor: default;
    padding: 2px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid var(--ultralight-grey-color);
    font-size: 0.85rem;
    color: var(--dark-color);
}

.pac-logo:after {
    content: '';
    padding: 8px;
    height: 20px;
    box-sizing: border-box;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 120px 14px;
    margin: 5px 0 0 0;
}
