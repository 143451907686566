// Hint: This file is used for changing global default material components

// MatDialog
// --------------------------------
.upscore-stepper-dialog-container .mat-dialog-container {
    .upscore-stepper {
        height: 75vh;
        width: 75vw;
        max-width: 1200px;
    }

    padding: 0;
}

.add-measure-dialog-container .mat-dialog-container {
    height: 75vh;
    width: 75vw;
    max-width: 1200px;
    padding: 0;
}

.measure-wizard-dialog-container .mat-dialog-container {
    height: 75vh;
    width: 75vw;
    max-width: 1200px;
    padding: 0;
}

.view-edit-employees-container .mat-dialog-container {
    max-height: 75vh;
    width: 75vw;
    max-width: 1200px;
    padding: 0;
}

.user-settings-dialog-container .mat-dialog-container {
    height: 75vh;
    width: 75vw;
    max-width: 1200px;
    padding: 0;
}

.share-mobility-audit-dialog-container .mat-dialog-container {
    height: 75vh;
    width: 75vw;
    max-width: 1200px;
    padding: 0;
}

.error-cancel-continue-dialog-container .mat-dialog-container {
    display: flex;
    flex-direction: column;
    width: 30vw;
    padding: 1rem;
    max-width: 800px;
}

.account-details {
    height: auto !important;
    display: flex !important;
    width: 100%;
    box-sizing: border-box;
    padding-right: 16px !important;
    flex-direction: column !important;
    gap: 0.5rem !important;
    line-height: normal !important;

    .header {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-family: 'source-sans-pro-semi-bold';

        span {
            border: solid 0.1rem;
            border-radius: 0.5rem;
            padding: 0.3rem;
            font-size: 0.9em;
        }

        .premium {
            color: var(--bike-color);
        }

        .not-premium {
            color: var(--primary-color);
        }
    }

    .user-details {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        span:last-of-type {
            margin-bottom: 1rem;
            font-size: 0.9em;
            color: var(--dark-grey-color);
        }
    }
}

.export-dialog-container .mat-dialog-container {
    overflow: hidden !important;
}

.ngx-toastr {
    width: 25rem !important; // Specify toastr-dialog width for all devices
}

// MatButtons
// --------------------------------
.mat-button,
.mat-flat-button {
    border-radius: var(--button-border-radius) !important;
    padding: 3px 25px !important;
}

// MatInputs
// --------------------------------
mat-label {
    font-family: source-sans-pro-semi-bold;
}

.mat-form-field-underline {
    background-color: var(--superlight-grey-color) !important;
    height: 2px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.7rem 0;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: var(--dark-color);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    opacity: 1 !important;
    color: var(--superlight-grey-color);
}

// removes padding from mat-form-fields in dashboard-page.component.html
#utility-wrapper .mat-form-field-wrapper {
    padding: 0 !important;
}

// Mat expansion panel
// --------------------------------
.mat-expansion-panel {
    box-shadow: var(--box-shadow) !important;

    mat-panel-title {
        color: var(--primary-color) !important;
        font-family: 'source-sans-pro-semi-bold';
        font-size: 16px;
    }

    .mat-expansion-panel-header[aria-disabled='true'] {
        opacity: 0.3;
    }

    .mat-expansion-indicator::after {
        color: var(--dark-color);
    }
}

// Mat chip
// --------------------------------
.mat-chip.mat-standard-chip {
    background-color: var(--ultralight-grey-color);
    padding: 0.063rem 0.625rem 0.188rem;
    border-radius: 1.563rem;
    font-family: 'source-sans-pro-semi-bold';
}

.mat-chip-list {
    pointer-events: none;
}

// Mat menu panel
// --------------------------------

.mat-menu-panel {
    box-shadow: var(--box-shadow) !important;
    min-height: unset !important;

    .mat-menu-content:not(:empty) {
        padding: 0 !important;
    }

    .mat-menu-item {
        padding-right: 2.5rem;
        color: var(--dark-color);

        &.warn {
            color: var(--danger-color);

            .mat-icon {
                color: var(--danger-color);
            }
        }
    }

    .mat-icon {
        font-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        color: var(--dark-color);
        position: relative;
    }
}

// SnackBar
.mat-snack-bar-container {
    background: transparent;
    box-shadow: none;
    width: 100%;
    min-width: 0;
    padding: 0.8rem;
    color: rgba(255, 255, 255, 1);
}

.sentry-error-embed-wrapper {
    z-index: 1000 !important;
}
