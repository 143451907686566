:root {
    /* primary colors */
    --primary-color: #11548b;
    --medium-primary-color: #7aa0be;
    --light-primary-color: #aec6d8;
    --ultralight-primary-color: #e4ecf2;

    --warn-color: #ffc107;

    /* callout colors */
    --callout-info-border-color: #a4c1fa;
    --callout-info-background-color: #f5f8ff;
    --callout-info-icon-color: #3563ca;
    --callout-warning-background-color: #fef2f2;
    --callout-warning-border-color: #e2b2b0;
    --callout-warning-icon-color: #c53931;

    /* transport colors */
    --walk-color: #36c5f1;
    --bike-color: #2eb68d;
    --public-transport-color: #ff9601;
    --car-driver-color: #e01e6a;
    --car-passenger-color: #a10f65;
    --bus-color: #fac06fe1;
    --subway-color: #fccf91e1;
    --radius-color: #11548b;

    /* light transport colors */
    --light-walk-color: #bae6f4;
    --light-bike-color: #b9e2d6;
    --light-public-transport-color: #f8d8ab;
    --light-car-driver-color: #efb4cb;
    --light-car-passenger-color: #dcb0c9;

    /* dark - light colors */
    --dark-color: #1d1c2d;
    --light-color: #ffffff;

    /* grey colors */
    --dark-grey-color: #505060;
    --medium-grey-color: #aaaaaa;
    --light-grey-color: #d3d2d3;
    --superlight-grey-color: #ebeaeb;
    --ultralight-grey-color: #f5f5f5;

    /* other colors */
    --layout-background-color: #fbfbfb;

    --heatmap-gradient: radial-gradient(
        circle,
        rgba(255, 0, 0, 1),
        rgba(255, 57, 0, 1),
        rgba(255, 113, 0, 1),
        rgba(255, 170, 0, 1),
        rgba(249, 198, 0, 1),
        rgba(244, 227, 0, 1),
        rgba(238, 255, 0, 1),
        rgba(193, 255, 0, 1),
        rgba(147, 255, 0, 1),
        rgba(102, 255, 0, 1),
        rgba(102, 255, 0, 0)
    );

    /* action colors */
    --success-color: #257f1c;
    --danger-color: #931d2b;

    /* sidebar vars */
    --sidebar-margin: 2rem;

    /* kpi cards vars */
    --kpi-card-margin: 2rem;
    --kpi-card-width: 17.5rem;
    --kpi-card-border-radius: 4px;

    /* location cards vars */
    --company-card-margin: 2rem;
    --company-card-width: 20rem;
    --company-card-border-radius: 4px;

    /* button */
    --button-border-radius: 4px;

    /* box shadow */
    --box-shadow: 0px 0px 10px var(--superlight-grey-color);

    /* layout */
    --main-content-padding: 2rem;

    /* masonry */
    --masonry-width: 21.5rem;
    --masonry-spacing: 60px;

    --base-z: 0;
    --trip-details-line-z: 0;
    --trip-details-icon-z: 1;
    --language-switch-z: 1;
    --mat-tab-header-z: 2;
    --sidebar-z: 3;
    --map-overlay-z: 5;
    --sidebar-info-z: 999;
    --map-sidebar-button-overlay: 999;
    --employee-trip-details-z: 999;
    --kpi-card-z: 999;
    /* angular cdk overlay is 1000 */
    --autocomplete-z: 1001;
    --overlay-z: 9999; /* This is for overlays to stop people from doing anything while waiting*/
    --loading-indicator-z: 10000;

    /* export dialog */
    --export-width: calc(2 * var(--kpi-card-width) + 4 * var(--kpi-card-margin) + 70px);
    --export-height: calc((297 / 210) * var(--export-width));
    --export-scale-factor: 0.7;
}
