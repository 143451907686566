// Hint: This file should only contain your imports!

@import 'mat-themes';
@import 'mat-overwrites';
@import 'base';
@import 'google-overwrites';
@import 'variables.css';
@import 'typography';
@import 'spacing';

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

.invisible {
    pointer-events: none !important;
    opacity: 0;
}

hr {
    border: 1px solid var(--superlight-grey-color);
}
