// Hint: Holds the boilerplate code for the project.
// Including standard styles such as resets and typographic rules and styles, which are commonly used throughout your project.

// fonts

@font-face {
    font-family: source-sans-pro-regular;
    src: url('../assets/fonts/SourceSansPro-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: source-sans-pro-bold;
    src: url('../assets/fonts/SourceSansPro-Bold.ttf');
    font-weight: normal;
}

@font-face {
    font-family: source-sans-pro-semi-bold;
    src: url('../assets/fonts/SourceSansPro-SemiBold.ttf');
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
    font-family: source-sans-pro-bold;
    font-weight: normal;
}

body {
    font-family: source-sans-pro-regular, 'Helvetica Neue', sans-serif;
}

// general css

* {
    margin: 0;
    font-weight: normal;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    color: var(--dark-color);
    background-color: var(--layout-background-color);
}

// buttons

.primary-button-filled {
    background-color: var(--primary-color);
    color: var(--light-color);
    text-transform: uppercase;
}

.primary-button-filled:disabled {
    color: var(--light-color) !important;
    opacity: 0.3;
}

.cancel-button-filled {
    text-transform: uppercase;
    background-color: var(--superlight-grey-color);
    color: var(--dark-color);
}

.button-with-icon {
    .mat-button-wrapper {
        display: flex;
        align-items: center;
    }

    .mat-icon {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
    }
}

button.show-mat-menu-button {
    background-color: var(--ultralight-grey-color);
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    mat-icon {
        font-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem !important;
    }
}

// map overlays

.map__overlay--bottom {
    position: absolute;
    bottom: 2.9rem;
    left: 2.9rem;
    z-index: var(--map-overlay-z);
    align-items: flex-end;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto auto;
    pointer-events: none;
}

.map__overlay--top {
    position: absolute;
    top: 2.9rem;
    left: 10.9rem;
    z-index: var(--map-overlay-z);
    align-items: flex-start;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto auto;
    pointer-events: none;
}

// seperator
.sidebar__separator {
    border-color: var(--ultralight-grey-color);
    border-style: solid;
    opacity: 0.7;
    margin-top: var(--sidebar-margin);
}

.map__error-overlay {
    top: var(--main-content-padding);
    right: var(--main-content-padding);
    cursor: pointer;
    position: absolute;
    background: var(--light-color);
    z-index: var(--map-overlay-z);
    border-radius: 30rem;
    border-bottom: none;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--dark-color);
    font-size: 1.125rem;
    font-family: source-sans-pro-semi-bold;

    span {
        padding-left: 0.5rem;
    }
}
